// Curtain
@mixin curtain($position: fixed) {
  background-color: rgba(invert($background), 0.9);
  bottom: 0;
  display: none;
  height: 100%;
  left: 0;
  position: $position;
  top: 0;
  width: 100%;
  z-index: 9;
}

// Uncomment to compile style
// .curtain {
//   @include curtain;
// }

// Gradients
@mixin radial-gradient($color1, $color2) {
  @include browser-prefix(background-image, gradient(center bottom, ellipse farthest-corner, $color1 0%, $color2 100%));
  background-image: -webkit-gradient(radial, center bottom, 0, center bottom, 359, color-stop(0, $color1), color-stop(100, $color2));
}

@mixin animate-left($time: 35s) {
  -webkit-animation: Gradient $time ease infinite;
  -moz-animation: Gradient $time ease infinite;
  animation: Gradient $time ease infinite;
}

@mixin Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-webkit-keyframes Gradient {
  @include Gradient;
}

@-moz-keyframes Gradient {
  @include Gradient;
}

@keyframes Gradient {
  @include Gradient;
}

@mixin animated-text($time: 60s) {
  @include animate-left($time);
  background: linear-gradient(to right, $link, $callout, $callout-alt, $callout-alt, $callout, $link);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 1600%;
}

.animated-text {
  @include animated-text;
}

// Generic Content Overlay
@mixin overlay($background-color: rgba($type, 0.75), $content: $foreground) {
  background-color: $default;
  color: $content;

  &:before {
    background: $background-color;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    z-index: 1;
  }
} // End overlay

.overlay {
  @include overlay;

  > .wrap {
    z-index: 2;
  }
}

// Modal
@mixin modal {
  @include horizontal-center;
  background-color: $background;
  display: none;
  max-width: $phone;
  padding: 60px;
  top: $double-space;
  width: 100%;
  z-index: 10;

  // Secondary Mobile - Phone & Below
  @media screen and (max-width: $phone) {
    top: 0;
  } // End Secondary Mobile
} // End modal

// Uncomment to compile style
// .modal {
//   @include modal;
// }

// Wrap
@mixin wrap($width: 90%, $max-width: auto) {
  @include better-box(flex);
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-width;
  width: $width;

  .medium-wrap, .small-wrap {
    // Secondary Mobile
    @media screen and (max-width: $phone) {
      width: 100%;
    } // End Secondary Mobile
  }
} // End wrap

.wrap {
  @include wrap(90%, 80em);
}

.medium-wrap {
  @include wrap(90%, 60em);
}

.small-wrap {
  @include wrap(90%, 50em);
}

.small-wrap {
  @include wrap(90%, 42em);
}

.mini-wrap {
  @include wrap(90%, 42em);
}

@mixin larger {
  font-size: 1.4em;
}

.larger {
  @include larger;
}

@mixin smaller {
  font-size: 0.8em;
}

@mixin tag($color: $foreground) {
  @include better-box;
  @include no-select;
  background: rgba($color, 0.2);
  border-radius: 0.25em;
  color: $color;
  font-family: $font;
  font-size: 0.875em;
  letter-spacing: 0.2em;
  margin-bottom: 0.313em;
  padding: 0.5em;
  text-decoration: none;
  text-transform: uppercase;
}

a.tag {
  @include tag;
}

@mixin nav-link {
  font-family: $font-bold;
  font-size: 0.875em;
  letter-spacing: 0.12em;
  line-height: 1.4;
  text-transform: uppercase;
}

@mixin cta($background-color: $foreground, $color: $background) {
  @include better-box(inline-flex);
  @include nav-link;
  background-color: $background-color;
  color: $color;
  // cursor: pointer;
  font-family: "Eighties";
  font-size: 1.1em;
  justify-content: center;
  padding: calc(0.6em + 1px) 1.6em calc(0.8em + 1px);
  width: auto;

  span, svg {
    @include better-box;
    float: left;
  }

  &:hover {
    background-color: rgba($color, 1);
    color: $background-color;

    svg {
      & use {
        fill: $color;
        stroke: $background-color;
      }
    }
  }
} // End cta mixin

a.cta, button {
  @include cta;
}

a.cta-callout {
  @include cta($callout-alt, $background);
}

.read-more {
  color: $default;
  font-size: 1.125em;
  text-decoration: underline;
}

.half {
  @include better-box;
  margin-top: 0;
  width: 47.5%;

  &.left {
    clear: left;
    margin-right: 5%;
  }

  &.right {
    clear: right;
  }
} // End .half

// Avatar
@mixin avatar($background-color: #98F5F4) {
  @include better-box;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $background-color;
  background-size: cover;
  border-radius: 100%;
  height: 6em;
  width: 6em;

  // Secondary Mobile - Phone & Below
  @media screen and (max-width: $phone) {
    height: 5em;
    width: 5em;
  } // End Phone & Below
} // End avatar mixin

.avatar {
  @include avatar;

  &-admin {
    @include avatar($background);
  }
} // End .avatar

// Note
.note {
  font-size: 0.875em;
}

.caption {
  top: 1em;
  color: $foreground;
  font-family: "Acumin Pro";
  font-size: 0.8em;
  right: 1em;
  position: absolute;
  z-index: 2;
}

// Section Header
.section-header {
  @include full-box;
  margin-bottom: $space;
  text-align: center;
}

// "Back to ..." Callout
.back-callout {
  @include full-box;
  text-align: center;
}

.no-padding {
  &-top {
    padding-top: 0;
  }
}

.br-on-mobile {
  display: none;

  // Secondary Mobile - Phone & Below
  @media screen and (max-width: $phone) {
    display: static;
  } // End Phone & Below
}

.blink {
  animation: blink-animation 1s steps(2, start) infinite;
  -webkit-animation: blink-animation 1s steps(2, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

// Alert / Mailchimp Message
.msg-alert {
  display: inline-block;
  width: 100%;
  z-index: 9;

  &:before {
    content: "";
    flex-basis: 100%;
    height: 0;
    margin: 0;
  }

  p {
    @extend .animated-text;
    border-radius: 0.4em;
    border: 0.1em solid $callout-tert;
    font-size: 1.4em;
    line-height: 1.2;
    margin-bottom: 0;
    margin: 1em auto;
    max-width: 32em;
    padding: 0.6em 0.6em 0.7em;
    position: relative;
    width: auto;
    z-index: 1;
  }
}
