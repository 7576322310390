// Menu
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

// Bounce
@mixin bounce-animation-keyframes {
	0%, 100% {
    @include browser-prefix(transform, translateY(-0.24em));
  }

  60% {
    @include browser-prefix(transform, translateY(0.44em));
  }
}

@-webkit-keyframes bounce-animation {
  @include bounce-animation-keyframes;
}

@keyframes bounce-animation {
  @include bounce-animation-keyframes;
}

// Marquee
@keyframes marquee {
  100% {
   transform: translateX(-100%);
  }
}

// Logo Color Animation
@mixin logo-color-animation {
  0% {
    fill: $callout;
  }

  50% {
    fill: $callout-alt;
  }

  100% {
    fill: $callout;
  }
}

@-webkit-keyframes logo-color-animation {
  @include logo-color-animation;
}

@keyframes logo-color-animation {
  @include logo-color-animation;
}

// Link Color Animation
@mixin link-color-animation {
  0% {
    color: $callout-alt;
  }

  50% {
    color: $callout;
  }

  100% {
    color: $callout-alt;
  }
}

@-webkit-keyframes link-color-animation {
  @include link-color-animation;
}

@keyframes link-color-animation {
  @include link-color-animation;
}

// SVG Gradient Animation
@mixin svg-gradient-animation {
  0% {
    fill: $foreground;
    stroke-dasharray: 24;
    stroke: $link;
    stroke-width: 1em;
  }

  50% {
    fill: $callout;
    stroke: $callout;
    stroke-dasharray: 3000;
    stroke-width: 1em;
  }

  100% {
    fill: $foreground;
    stroke: $link;
    stroke-dasharray: 24;
    stroke-width: 1em;
  }
}

@-webkit-keyframes svg-gradient-animation {
  @include svg-gradient-animation;
}

@keyframes svg-gradient-animation {
  @include svg-gradient-animation;
}

// Router
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
