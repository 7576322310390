//0.0 BASE
//  0.1 Variables
//  0.2 Generics, Utilities, & Consolidations
//  0.3 Resets
//  0.4 Webfonts

//1.0 LAYOUT
//  1.1 Header Structure
//  1.2 Footer Structure
//  1.3 Headings
//  1.4 Forms
//  1.5 Lists
//  1.6 Pagination

//2.0 MODULES
//  2.# Page Specific

//3.0 RESPONSIVE
//  3.1 Only Computer - No tablet or below
//  3.2 Only Tablet & Above - No Mobile Phone
//  3.3 Primary Mobile - Tablet & Below
//  3.4 Secondary Mobile - Phone & Below
//  3.5 iPad Only
//  3.6 iPhone Only

//4.0 Themes
//  4.# If Applicable

// 0.0 BASE
// -----------------------------------------------------------------------------

// 0.1 Variables

// Responsive
$large-screen: 1280px;
$tablet: 1024px;
$big-phone: 880px;
$phone: 680px;
$small-phone: 480px;

// Color
$default: #000000;
$background: #000000; // Primary background color
$background-alt: #F4F4F4; // Alternate background color
$border-color: rgba($default, 0.2);
$callout: #ef4469;
$callout-alt: #f7b173;
$callout-tert: #405398;
$callout-secondary: #EB9E39;
$error: $callout-alt;
$error-hover: lighten($error, 2.5%);
$link: #61acf1;
$link-hover: #4a638b;
$link-alt: rgba($background, 0.4);
$type: $default;
$type-alt: $background;
$type-tert: rgba($type, 0.6);
$foreground: #FFFFFF;
$shadow: rgba($background, 0.1);

// Space
$base: 16px;
$tablet-base: 15px;
$phone-base: 15px;

$space: 2rem;
$half-space: $space / 2;
$double-space: $space * 2;
$minor-space: 1.250rem;
$major-space: $minor-space * 6;
$double-minor-space: $minor-space * 2;
$micro-space: 0.750rem;
$nano-space: $micro-space / 2;

// Fonts
$font: "Quincy CF";
$font-italic: "Quincy CF";
$font-bold: "Quincy CF";
$font-bold-italic: "Quincy CF";
$font-sec: "Eighties";
$font-sec-2: "Eighties";
$font-tert: "Eighties";
$font-stack: $font, Arial, Helvetica, sans-serif;
$social: "SSSocialRegular";

// Misc
$animate-time: 0.18s;
$border-radius: 0.250em;

// Base Imports
@import "./assets/stylesheets/base/utilities";
@import "./assets/stylesheets/base/resets";
@import "./assets/stylesheets/base/cursor";
@import "./assets/stylesheets/base/generics";
@import "./assets/stylesheets/base/fonts";
@import "./assets/stylesheets/base/global";
@import "./assets/stylesheets/base/forms";

// 1.0 LAYOUT - Global Structure
// -----------------------------------------------------------------------------

// Headings
@import "./assets/stylesheets/layout/headings";

// Forms
// @import "layout/forms";

// 2.0 MODULES - Page Specific
// -----------------------------------------------------------------------------
@import "./assets/stylesheets/modules/animations";

// Print
// @import "base/print";

// 3.0 RESPONSIVE
// -----------------------------------------------------------------------------

// Only Computer - No tablet or below
// @media screen and (min-width: $tablet) {} // End Only Computer

// Only Tablet & Above - No Mobile Phone
// @media screen and (min-width: $phone) {} // End Tablet & Above

// Only Big Phone & Above - No Mobile Phone
// @media screen and (min-width: $big-phone) {} // End Big Phone & Above

// Primary Mobile - Tablet & Below
// @media screen and (max-width: $tablet) {} // End Tablet & Below

// Big Phone & Below
// @media screen and (max-width: $big-phone) {} // End Big Phone & Below

// Secondary Mobile
// @media screen and (max-width: $phone) {} // End Secondary Mobile

// iPad Only
// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {} // End iPad Only

// iPhone Only
// @media only screen and (max-device-width: 480px) {} // End iPhone Only
