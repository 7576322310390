html, body {
  -webkit-text-size-adjust: 100%;
  min-height: 100vh;
}

body {
  background-color: $background;
  color: $type;
  font-family: $font-stack;
  font-size: $base;

  // Primary Mobile - Tablet & Below
  @media screen and (max-width: $tablet){
    font-size: $tablet-base;
  } // End Tablet & Below

  // Secondary Mobile - Phone & Below
  @media screen and (max-width: $phone){
    font-size: $phone-base;
  } // End Phone & Below
} // End body

// Section
@mixin section($display: inline-block) {
  @include full-box(block);
  padding: $major-space 0;

  // Secondary Mobile - Phone & Below
  @media screen and (max-width: $phone) {
    padding: 5em 0;
  } // End Phone & Below
}

section {
  @include section;

  &.alt {
    background-color: $background-alt;
  }

  &.short {
    padding-bottom: $double-space;
  }
} // End section

p {
  font-family: $font;
  font-size: 1.125em;
  line-height: 160%;
  margin-bottom: 1em;

  &, em {
    font-weight: 400;
  }

  &.large {
    @include larger;
    font-family: $font-sec;
  }
} // End p

@mixin p-alt {
  color: $type-tert;
  font-size: 1.2em;
}

.p-alt {
  @include p-alt;
}

i {
  font-style: normal;
}

strong {
  font-family: $font;
  font-weight: 800;

  em {
    font-family: $font;
    font-weight: 800;
    font-style: italic;
  }
}

em {
  font-family: $font;
  font-style: italic;

  strong {
    font-weight: 800;
  }
}

hr {
  @include full-box(table);
  background-color: $border-color;
  height: 1px;
  margin-bottom: $space;

  &.bottom-space {
    margin-bottom: 7.5em;
  }

  &.spacer {
    background-color: rgba($border-color, 0);
  }
}

br {
  &.mobile-br {
    display: none;

    // Big Phone & Below
    @media screen and (max-width: $big-phone) {
      display: block;
    } // End Big Phone & Below
  }
}

a {
  color: $callout-alt;
  position: relative;

  &:hover {
    color: $callout;
  }
}

img {
  @include full-box;
}
